// @flow
import * as React from "react";
import "./ProductsDetailPage.scss";
import type {ProductDetail} from "../reducers/contentReducer";
import {PriceBox} from "./PriceBox";
import {Loading} from "./Loading";
import TextBlock from "./TextBlock";
import {ProductTile} from "./ProductTile";
import {Gallery} from "./Gallery";
import {Trans} from "react-i18next";
import SwimLane from "./SwimLane";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom-v5-compat";
import ContrastColorUpdater from "./ContrastColorUpdater";

type ProductsDetailPageProps = {
    onLoad: string => void,
    cleanup: () => void,
    productId: string,
    currentProduct: ProductDetail,
    addToWishList: () => void,
    removeFromWishList: () => void,
    isOnWishList: boolean,
    highlightColor: string,
    contrastColor: string,
    disableWishlist: boolean,
};

const ProductsDetailPage = ({
                                onLoad,
                                cleanup,
                                productId,
                                currentProduct,
                                addToWishList,
                                removeFromWishList,
                                isOnWishList,
                                contrastColor,
                                highlightColor,
                                disableWishlist,
                            }: ProductsDetailPageProps) => {
    const [isFitmentCollapsed, setIsFitmentCollapsed] = React.useState(true);
    const location = useLocation();
    const productIdFromUrl = (new URLSearchParams(location.search)).get("productId");
    const navigate = useNavigate();

    React.useEffect(() => {
        onLoad(productIdFromUrl);
        return () => cleanup;
    }, [productId, onLoad, cleanup, productIdFromUrl]);

    const allowedFitmentSize = 6;

    const renderFitments = (fitments, hasMore) => {
        return <React.Fragment>
	{fitments.map(fitment => {
                return <div key={fitment} className={"fitmentElement"}>{fitment}</div>;
            })}
	{hasMore && <div>...</div>}
        </React.Fragment>;
    };

    if (!currentProduct) {
        return <Loading/>;
    }

    return <div className="page productsDetailPage" data-testid={"productDetailPage"}>
	<ContrastColorUpdater/>
	<div className="leftBox">
		<Gallery images={currentProduct.attachments.images} videos={currentProduct.attachments.videos}/>
		{!!currentProduct.product.fitments.length && <div className="fitment" data-testid={"fitments"}>
			{currentProduct.product.fitments.length > allowedFitmentSize
                    ? <TextBlock className={isFitmentCollapsed ? "collapsed" : "open"}
                                 title={<Trans i18nKey="productDetailsPage.fitmentTitle"/>}
                                 onClick={() => setIsFitmentCollapsed(!isFitmentCollapsed)}
                    >
	{isFitmentCollapsed
                            ? renderFitments(currentProduct.product.fitments.filter((fitment, index) => index < allowedFitmentSize), currentProduct.product.fitments.length > allowedFitmentSize)
                            : renderFitments(currentProduct.product.fitments, false)
                        }
                    </TextBlock>
                    : <TextBlock title={<Trans i18nKey="productDetailsPage.fitmentTitle"/>}>
	{currentProduct.product.fitments.map(fitment => {
                            return <div key={fitment} className={"fitmentElement"}>{fitment}</div>;
                        })}
                    </TextBlock>
                }
		</div>}
	</div>
	<div className="rightBox">
		<div className="title" data-testid={"title"}>{currentProduct.product.name}</div>
		<div className="priceBox" data-testid={"price"}>
			<PriceBox price={currentProduct.product.price}
                          rrp={currentProduct.product.recommendedRetailPrice}
                          unitPrice={currentProduct.product.unitPrice}
                          withMontage={!currentProduct.product.distributionChannels.shipping && !currentProduct.product.distributionChannels.pickup}
                          highlightColor={highlightColor}
                />
		</div>
		{!disableWishlist &&
		  <div className="favBtn">
			{ !isOnWishList
          ? <button onClick={() => addToWishList()} style={{color: contrastColor}} data-testid={"addBookmark"}><Trans i18nKey="productDetailsPage.addToWishList"/></button>
          : <button onClick={() => removeFromWishList()} style={{color: contrastColor}} data-testid={"removeBookmark"}><Trans i18nKey="productDetailsPage.removeFromWishList"/></button>
      }
		  </div>
    }
		<div className={"disclaimer"}/>
		{!!currentProduct.product.description && <div className={"description"} data-testid={"description"}>
			<TextBlock title={<Trans i18nKey="productDetailsPage.description"/>}>
				<div dangerouslySetInnerHTML={{__html: currentProduct.product.description}}/>
			</TextBlock>
		</div>}
		{currentProduct.tireLabel && <div className={"tireLabel"}/>}
	</div>
	<div className="navigation">
		<div>
			<button className={"button secondary"} type={"button"} onClick={() => navigate(-1)}
                        style={{color: contrastColor}} data-testid={"backToOverview"}><Trans
                    i18nKey="productDetailsPage.backLinkButtonLabel"/>
			</button>
		</div>
		<div>
			<button className={"button secondary"} data-testid={"topLink"}
                        onClick={() => document.querySelector("body").scrollIntoView()}><Trans
                    i18nKey="productDetailsPage.topLink"
                    style={{color: highlightColor}}
                />
			</button>
		</div>
	</div>
	<div className="crossSelling" data-testid={"crossSelling"}>
		<TextBlock title={<Trans i18nKey="productDetailsPage.crossSelling"/>}>
			<SwimLane>
				{currentProduct.relatedProducts.map(product =>
					<ProductTile
                            key={product.id}
                            productItem={product}
                            highlightColor={highlightColor}
                        />)}
			</SwimLane>
		</TextBlock>
	</div>
    </div>;
};

export default ProductsDetailPage;