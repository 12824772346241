// @flow
import * as React from "react";
import "./CategoryProductsPage.scss";
import {ProductTile} from "./ProductTile";
import type {Product} from "../models/Product";
import type {PagedProduct} from "../reducers/contentReducer";
import type {VehicleOption} from "../models/VehicleOption";
import InfiniteScroll from "react-infinite-scroll-component";
import {useLocation} from "react-router-dom-v5-compat";
import ContrastColorUpdater from "./ContrastColorUpdater";
import {Loading} from "./Loading";

export type SortOption = {
    fieldname: "price",
    direction: "ASC"
}

type ProductsPageProps = {
    onLoad: (string, ?number, SortOption) => void,
    pagedProducts: PagedProduct,
    totalItems: number,
    isIdle: boolean,
    fuzzy: string,
    vehicleOption: VehicleOption,
    amount: number,
    contrastColor: string,
    t: string => string,
};

export const SORT_OPTIONS = {
    SCORE: {
        label: "sortOptions.score",
        value: {
            fieldname: "score",
            direction: "DESC"
        }
    },
    NAME_ASC: {
        label: "sortOptions.nameAsc",
        value: {
            fieldname: "name.keyword",
            direction: "ASC"
        }
    },
    NAME_DESC: {
        label: "sortOptions.nameDesc",
        value: {
            fieldname: "name.keyword",
            direction: "DESC"
        }
    },
    PRICE_ASC: {
        label: "sortOptions.priceAsc",
        value: {
            fieldname: "price",
            direction: "ASC"
        }
    },
    PRICE_DESC: {
        label: "sortOptions.priceDesc",
        value: {
            fieldname: "price",
            direction: "DESC"
        }
    }
};

const CategoryProductsPage = ({
                                  onLoad,
                                  pagedProducts,
                                  totalItems,
                                  isIdle,
                                  fuzzy,
                                  vehicleOption,
                                  amount,
                                  t,
                                  highlightColor
                              }: ProductsPageProps) => {
    const [page, setPage] = React.useState(0);
    const [isSortMenuOpen, setIsSortMenuOpen] = React.useState(false);
    const [sortOrder, setSortOrder] = React.useState(SORT_OPTIONS.SCORE);
    const location = useLocation();
    const selectedCategoryKey = (new URLSearchParams(location.search)).get("category");

    React.useEffect(() => {
        setPage(0);
    }, []);

    const handleScroll = () => {
        const node = document.querySelector(".app");
        if (node) {
            node.style.minHeight = node.offsetHeight + "px";
        } else {
            node.style.minHeight = 0;
        }
    };

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
            const node = document.querySelector(".app");
            if (node) {
                node.style.minHeight = 0;
            }
        };
    }, []);


    React.useEffect(() => {
        onLoad(selectedCategoryKey, page, sortOrder.value);
    }, [fuzzy, vehicleOption, sortOrder, onLoad, selectedCategoryKey, page]);


    const renderSortMenu = () => {
        const handleSelection = sortOption => {
            setIsSortMenuOpen(false);
            setSortOrder(sortOption);
        };

        /* eslint-disable jsx-a11y/anchor-is-valid */
        return <React.Fragment>
            {isSortMenuOpen
                ? <div className={"sortMenu open"}>
                    <a className={SORT_OPTIONS.SCORE === sortOrder ? "active" : "inactive"}
                       onClick={() => handleSelection(SORT_OPTIONS.SCORE)}>{t(SORT_OPTIONS.SCORE.label)}</a>
                    <a className={SORT_OPTIONS.NAME_ASC === sortOrder ? "active" : "inactive"}
                       onClick={() => handleSelection(SORT_OPTIONS.NAME_ASC)}>{t(SORT_OPTIONS.NAME_ASC.label)}</a>
                    <a className={SORT_OPTIONS.NAME_DESC === sortOrder ? "active" : "inactive"}
                       onClick={() => handleSelection(SORT_OPTIONS.NAME_DESC)}>{t(SORT_OPTIONS.NAME_DESC.label)}</a>
                    <a className={SORT_OPTIONS.PRICE_ASC === sortOrder ? "active" : "inactive"}
                       onClick={() => handleSelection(SORT_OPTIONS.PRICE_ASC)}>{t(SORT_OPTIONS.PRICE_ASC.label)}</a>
                    <a className={SORT_OPTIONS.PRICE_DESC === sortOrder ? "active" : "inactive"}
                       onClick={() => handleSelection(SORT_OPTIONS.PRICE_DESC)}>{t(SORT_OPTIONS.PRICE_DESC.label)}</a>
                </div>
                : <a className={"sortMenu"}
                     onClick={() => setIsSortMenuOpen(!isSortMenuOpen)}>{t("sortOptions.chooseCta")}</a>
            }
        </React.Fragment>;
    };

    const renderProducts = () => {
        if (!pagedProducts.length && page === 0) {
            return <Loading/>;
        }
        return <React.Fragment>
            <ContrastColorUpdater/>
            {renderSortMenu()}
            <div className={"productGrid"}>
                {pagedProducts.map((productItem: Product) => <ProductTile
                    key={productItem.id}
                    productItem={productItem}
                    highlightColor={highlightColor}
                />)}
                <div className="product ph"/>
                <div className="product ph"/>
                <div className="product ph"/>
                <div className="product ph"/>
                <div className="product ph"/>
                <div className="product ph"/>
                <div className="product ph"/>
                <div className="product ph"/>
                <div className="product ph"/>
                <div className="product ph"/>
                <div className="product ph"/>
            </div>
        </React.Fragment>;
    };

    return <div className="page categoryProductsPage" data-testid={"categoryProducts"}>
        <InfiniteScroll
            className={"productGrid"}
            dataLength={pagedProducts.length}
            hasMore={totalItems > pagedProducts.length}
            loader={<Loading/>}
            next={() => setPage(page => page + 1)}
            scrollThreshold={window.innerHeight ? (window.innerHeight + "px") : "300px"}
        >
            {renderProducts()}
        </InfiniteScroll>
    </div>;
};

export default CategoryProductsPage;
