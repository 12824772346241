// @flow

import React from "react";
import type {SlImage} from "../../../homepagemodul-2019/src/models/Product";
import type {Video} from "../models/Attachments";

type GalleryThumbnailProps = {
	type: "Video" | "Image",
	media: Video | SlImage,
	selectAttachment: SlImage => void
};

const GalleryThumbnail = (props: GalleryThumbnailProps) => {
	const TeaserImageStyle = {
		backgroundImage: `url(${props.media.previewImage ? props.media.previewImage.attachmentUrl : props.media.attachmentUrl})`
	};

	return props.type === "Image"
		? (
			<div
				className="hpm-previewImageWrapper"
				onClick={() => props.selectAttachment(props.media)}>
				<div className="hpm-previewImageBlur" style={TeaserImageStyle}/>
				<div className="hpm-previewImage" style={TeaserImageStyle}/>
			</div>
		)
		: (
			<div
				className="hpm-videoThumbnail hpm-previewImageWrapper"
				onClick={() => props.selectAttachment(props.media)}>
				<div className="hpm-previewImage" style={TeaserImageStyle}/>
			</div>
		);
};

export default GalleryThumbnail;
