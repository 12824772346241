// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {withTranslation} from "react-i18next";
import type {Dispatch} from "redux";
import {getCategory, getCategoryProducts} from "../actions/content";
import type {PagedProduct} from "../reducers/contentReducer";
import CategoryProductsPage from "../components/CategoryProductsPage";
import type {SortOption} from "../components/ProductsSearchPage";

type CategoryPageContainerProps = {
	onLoad: () => void,
	pagedProducts: PagedProduct[],
};

function mapStateToProps(state: ApplicationState, ownProps: CategoryPageContainerProps) {
	return {
		fuzzy: state.filter.fuzzy,
		vehicleOption: state.filter.vehicleOption,
		pagedProducts: state.content.pagedProducts,
		totalItems: state.content.totalItems || state.content.pagedProducts.length,
		isIdle: !state.content.requestQueue.length,
		amount: state.filter.amount,
		highlightColor: state.config.highlightColor,
	};
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: CategoryPageContainerProps) {
	return {
		onLoad: (categoryKey: string, page: number, sortOption: SortOption) => {
			if (page === 0) {
				dispatch(getCategory(categoryKey));
			}
			dispatch(getCategoryProducts([categoryKey], page, [sortOption]));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CategoryProductsPage));
