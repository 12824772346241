// @flow
import ReactGA from "react-ga4";
import type {Tracking} from "./models/Tracking";
import BaseTracker from "./BaseTracker";
import type {Product} from "../../models/Product";

export default class GoogleTracker extends BaseTracker implements Tracking {
	constructor(trackingIds: string[], withEcommerce: boolean = false, dealerId: string = "unknown", dealerName: string = "unknown", customerNo: string = "unknown") {
		super(trackingIds[0], withEcommerce);
		const gtagOptions = {
			"dimension1": dealerId,
			"dimension2": dealerName,
			"dimension3": customerNo,
		};

		ReactGA.initialize(trackingIds
			.map(id => ({
				trackingId: id,
				gaOptions: {},
				gtagOptions
			})),{
				testMode: false,
			}
		);
	}

	handleInsertToWatchlistEvent(event: {}, product: Product) {
		const addToWishlistBody = {
			currency: product.price.currency,
			value: product.price.value,
			items: [{
				item_id: product.id,
				item_name: product.name,
				affiliation: product.dealerId,
				item_category: "Accessories Navigator",
				price: product.price.value,
				quantity: 1,
			}],
		};
		ReactGA.event("add_to_wishlist", addToWishlistBody);
		this.handleGenericEvent(event.CATEGORIES.BOOKMARKS, event.ACTIONS.BOOKMARKS.ADD_PRODUCT, product.name);
	}

	handleGenericEvent(cat: string, action: string, label: any): void {
		ReactGA.event(action, {
			category: cat,
			label: label,
			event_label: label,
			event_source: "POS"
		});
	}

	handleAddItemEvent(cat: string, label: string, product: Product) {
		ReactGA.event({
			category: cat,
			label: label,
			event_label: label,
			action: product.name,
			event_source: "POS"
		});
	}

	handleRemoveItemEvent(cat: string, label: string, product: Product) {
		ReactGA.event({
			category: cat,
			label: label,
			event_label: label,
			action: product.name,
			event_source: "POS"
		});
	};

	handleClearCartEvent() {
	}
}
