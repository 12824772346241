// @flow
import * as React from "react";
import type {Product} from "../models/Product";
import "./ProductTile.scss";
import {Currency} from "./Currency";
import {Link} from "react-router-dom";

type Props = {
	productItem: Product,
	highlightColor: string,
};
export const ProductTile = (props: Props) => {
	const isShortened = props.productItem.name.length > 35;
	const productTitle = isShortened ? props.productItem.name.substring(0, 35) + "..." : props.productItem.name.substring(0, 35);
	const productImage = props.productItem.galleryImage || {};

	let className = "product";

	if (props.productItem.recommendedRetailPrice) {
		className = `${className} discounted`;
	}

	if (props.productItem.distributionChannels.mounting) {
		className = `${className} mounting`;
	}
	return <Link to={`/productDetail/?productId=${props.productItem.id}`} key={props.productItem.id}
				 className={className} data-testid={"productLink"}>
		<span className="icon_left">
			<span className=""/>
		</span>
		<span className="icon_right">
			<span className=""/>
		</span>
		<span className="imageContainer" data-testid={"productImage"}>
			<img src={productImage.previewImage ? productImage.previewImage.attachmentUrl : productImage.attachmentUrl}
				 alt={productTitle}
			/>
		</span>
		<span className="price" data-testid={"productPrices"}>
			<span className="retail_price_label" data-testid={"productRetailPrice"}>
				{!!props.productItem.recommendedRetailPrice && <Currency price={props.productItem.recommendedRetailPrice}/>}
			</span>
			{props.productItem.price && (
				<div className="price_container">
					<div className="price_label" style={{ color: props.highlightColor }} data-testid={"productPrice"}>
						<Currency price={props.productItem.price}/>
					</div>
					{props.productItem.unitPrice && (
						<div className="unit-price">
							<small>
								(<Currency price={props.productItem.unitPrice.pricePerUnit}/>/ {props.productItem.unitPrice.measuringUnit === "sqm" ? "m²" : props.productItem.unitPrice.measuringUnit === "cbm" ? "m³" : props.productItem.unitPrice.measuringUnit})
							</small>
						</div>
					)}
				</div>
			)}
		</span>
		<span className="productTitle" data-testid={"productTitle"}>{productTitle}</span>
		<span className="series" data-testid={"productSeries"}>
			{props.productItem.carlines.map((fitment, index) => {
				const key = props.productItem.id + fitment;
				if (index === 0) {
					return <React.Fragment key={key}>{fitment}</React.Fragment>;
				}
				if (index <= 1) {
					return <React.Fragment key={key}>{`, ${fitment}`}</React.Fragment>;
				}
				if (index === 2) {
					return <React.Fragment key={key}> und weitere...</React.Fragment>;
				}
				return null;
			})}
		</span>
	</Link>;
};
