import type {Tracking} from "./models/Tracking";
import EVENT_EN from "./Events";
import EVENT_DE from "./EventsDE";
import StatisticApi from "../../api/StatisticApi";
import type {Product} from "../../models/Product";
import {wishlistAdded, wishListCurrentRemoved, wishlistRemoved} from "../../reducers/bookmarksReducer";
import {configSuccessGotten} from "../../reducers/configReducer";
import {categorySuccessGotten, productSuccessGotten} from "../../reducers/contentReducer";
import {searchPhraseSet, vehicleSet} from "../../reducers/filterReducer";

export const trackers: Tracking[] = [];

export const tracker = store => next => action => {
	const EVENT = getLanguageCode(store, action).includes("de") ? EVENT_DE : EVENT_EN;

	switch (action.type) {
		case wishlistAdded.type: {
			addToWishlist(EVENT, action.payload.product);
			const statisticApi = new StatisticApi(getConfigId(store));
			statisticApi.trackAddedToWatchlist(action.payload.product.id);
			break;
		}
		case wishListCurrentRemoved.type:
		case wishlistRemoved.type: {
			logEvent(EVENT.CATEGORIES.BOOKMARKS, EVENT.ACTIONS.BOOKMARKS.REMOVE_PRODUCT, action.payload.product.name);
			break;
		}
		case configSuccessGotten.type: {
			logEvent(EVENT.CATEGORIES.SHOP, EVENT.ACTIONS.SHOP.LOADED);
			break;
		}
		case productSuccessGotten.type: {
			logEvent(EVENT.CATEGORIES.PRODUCT, EVENT.ACTIONS.PRODUCT.PRODUCT_VISITED, action.payload.product.name);
			break;
		}
		case vehicleSet.type: {
			if (action.payload.series || action.payload.model) {
				logEvent(
					EVENT.CATEGORIES.FILTER, EVENT.ACTIONS.FILTER.VEHICLE,
					`${action.payload.series ? action.payload.series.label : ""} ${action.payload.model ? action.payload.model.label : ""}`
				);
			}
			break;
		}
		case searchPhraseSet.type: {
			if (action.payload) logEvent(EVENT.CATEGORIES.SEARCH, EVENT.ACTIONS.SEARCH.QUERY, action.payload);
			break;
		}
		case categorySuccessGotten.type: {
			if (action.payload.selectedCategory) {
				logEvent(
					EVENT.CATEGORIES.FILTER,
					EVENT.ACTIONS.FILTER.CATEGORY,
					action.payload.selectedCategory.title
				);
			}
			break;
		}
		default: {
			break;
		}
	}
	return next(action);
};

const logEvent = (cat, action, label) => {
	trackers.forEach(tracker => {
		tracker.logEvent(cat, action, label);
	});
};

const addToWishlist = (event: {}, product: Product) => {
	trackers.forEach(tracker => {
		tracker.addToWishlist(event, product);
	});
};

const getLanguageCode = (store, action) => {
	try {
		return store.getState().config.trackingProperties.trackerLanguage ||
		action.payload.configurationDetails.trackingProperties.trackerLanguage || "";
	} catch {
		return "";
	}
};

const getConfigId = store => {
	try {
		return store.getState().config.dealerId || store.getState().config.dealerDetails.cfgKey || store.getState().config.dealerDetails.identifier.dealerNo;
	} catch (e) {
		return "";
	}
};
