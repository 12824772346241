// @flow
import React from "react";
import "./Header.scss";
import SearchBarContainer from "../container/SearchBarContainer";
import CarSelectorContainer from "../container/CarSelectorContainer";
import FilterContainer from "../container/FilterContainer";
import BookmarksContainer from "../container/BookmarksContainer";
import TimeoutContainer from "../container/TimeoutContainer";
import type {VehicleOption} from "../models/VehicleOption";
import {Link} from "react-router-dom";
import {useLocation, useNavigate} from "react-router-dom-v5-compat";

type HeaderProps = {
	vehicleOption: VehicleOption,
	vehicleMenuOpen: boolean,
	toggleVehicleMenu: () => void,
	toggleSearchBar: () => void,
	toggleI18nSelector: () => void,
	toggleBookmarks: () => void,
	bookmarksLength: number,
	availableLanguages: string[],
	resetBrand: () => void,
	hasMultipleBrands: boolean,
	contrastColor: string,
	disableSearchbar: boolean,
	disableWishlist: boolean,
	logoUrl: string
};

const Header = (props: HeaderProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const parentContext = location.pathname !== "/";
	const shouldIndent = parentContext || props.availableLanguages.length > 1;
	const showResetBrandButton = location.pathname === "/" && props.hasMultipleBrands;

	return <React.Fragment>
		<nav className={"Header"} data-testid={"header"}>
			<div className="button-group" data-testid={"buttonContainer"}>
				{parentContext
					? <button className={"back"} onClick={() => navigate(-1)} data-testid={"backButton"}>
						<span/>
					</button>
					: props.availableLanguages.length > 1
						? <button type={"button"} onClick={() => props.toggleI18nSelector()} className="i18n" data-testid={"langButton"}>
							<span/>
						</button>
						: null
				}
				{showResetBrandButton
					? <button type={"back"} onClick={() => props.resetBrand()} data-testid={"resetBrandButton"}>
						<span/>
					</button>
					: null
				}
			</div>
			<div className={shouldIndent ? "button-group indent" : "button-group"}>
				<Link to={"/"}>
					<div className={"Logo"} style={{
						backgroundImage: "url(" + props.logoUrl + ")"
					}} data-testid={"Logo"}/>
				</Link>
			</div>
			<div className="button-group">
				{(props.vehicleOption.series || props.vehicleOption.model)
					? <button type={"button"} data-testid={"vehicleButton"}
										onClick={() => props.toggleVehicleMenu()} className="cars selected">
						<span/>
						<span className={"vehicleOption"}>
							<span>
								{props.vehicleOption.series &&
									<span className={"seriesName"} data-testid={"vehicleSeries"}>
										{props.vehicleOption.series.label}
									</span>
								}
								{props.vehicleOption.model &&
									<span className={"modelName"} data-testid={"vehicleModels"}>
										{props.vehicleOption.model.label}
									</span>
								}
							</span>
						</span>
					</button>
					: <button type={"button"} onClick={() => props.toggleVehicleMenu()} className="cars" data-testid={"vehicleButton"}>
						<span/>
					</button>
				}

				{!props.disableWishlist &&
					<button type={"button"} onClick={() => props.toggleBookmarks()} className="bookmarks" data-testid={"bookmarksButton"}>
						<span/>
						{props.bookmarksLength ?
							<span className={"count"} style={{color: props.contrastColor}} data-testid={"bookmarkCount"}>{props.bookmarksLength}</span>
							: null
						}
					</button>
				}
				{!props.disableSearchbar &&
					<button type={"button"} onClick={() => props.toggleSearchBar()} className="search" data-testid={"searchIcon"}>
						<span/>
					</button>
				}
			</div>
		</nav>
		<SearchBarContainer/>
		<CarSelectorContainer/>
		<BookmarksContainer/>
		<FilterContainer/>
		<TimeoutContainer/>
	</React.Fragment>;
};

export default Header;
