// @flow
import * as React from "react";
import type {Price} from "../models/Product";
import {Currency} from "./Currency";
import {Trans} from "react-i18next";

type Props = {
	price: Price,
	rrp: ?Price,
	withMontage: boolean,
	highlightColor: string,
};
export const PriceBox = (props: Props) => {
	return <React.Fragment>
		<span className="retail_price_label">{!!props.rrp && <Currency price={props.rrp}/>}</span>
		{!!props.price && (
			<div className="price_and_unit_price" style={{ color: props.highlightColor }}>
				<span className="price_label">
					<Currency price={props.price}/>
				</span>
				{!!props.unitPrice && (
					<div>
						<small className="unit-price">
							(<Currency price={props.unitPrice.pricePerUnit}/> /{" "}
							{props.unitPrice.measuringUnit === "sqm" ? "m²" : props.unitPrice.measuringUnit === "cbm" ? "m³" : props.unitPrice.measuringUnit})
						</small>
					</div>
				)}
			</div>
		)}
		<div className={"vat"}><i><Trans i18nKey={props.withMontage ? "priceBox.withVatAndMontage" : "priceBox.withVat"}/></i></div>
	</React.Fragment>;
};
